<template>
  <div class="container page">
    <van-nav-bar :title="this.videoInfo.vod_name" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="movie-video">
      <div id="mse"></div>
    </div>
    <div class="movie-content">
      <div class="movie-descript">
        <p>{{ this.videoInfo.vod_name }}</p>
        <span>{{ this.videoInfo.count }}{{ $t("video.num_play") }}</span>
      </div>

      <div class="movie-body">
        <div class="movie-title">
          <div>
            <span>{{ $t("index.recmonmand") }}</span>
          </div>
        </div>
        <div class="movie-list">
          <div class="movie-play-item" @click="toPlayVideo(v.id)" v-for="(v, key) in moreVideoInfo" :key="key">
            <div>
              <img :src="v.vod_pic">
              <div>
                <div class="van-count-down">{{ v.time }}</div>
              </div>
            </div>
            <div>
              <p>{{ v.vod_name }}</p>
              <span>{{ v.count }}{{ $t("video.num_play") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Player from 'xgplayer';
import HlsPlugin from 'xgplayer-hls'
export default {
  data() {
    return {
      nowPlayVideoUrl: "",
      cover: "",
      userInfo: [],
      videoInfo: {},
      moreVideoInfo: {},
      is_play: false,
      times: null,
      is_see: 0
    };
  },
  methods: {
    back() {
      this.$router.push({ path: 'Home' })
    },
    getVideoInfo() {
      this.$http({
        method: 'get',
        data: { id: this.$route.query.id },
        url: 'video_get_info'
      }).then(res => {
        this.videoInfo = res.data;
        this.nowPlayVideoUrl = this.videoInfo.vod_play_url;
        this.cover = this.videoInfo.vod_pic;
        this.getVideo(this.videoInfo.vod_play_url, this.cover);
      })

    },
    toPlayVideo(id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '?id=' + id })
        location.reload();
      }
    },
    getMoreVideoItem() {
      this.$http({
        method: 'get',
        url: 'video_get_more_item'
      }).then(res => {
        this.moreVideoInfo = res.data;
      })
    },

    getVideo(url, img) {
      console.log(url)
      new Player({
        "id": "mse",
        "playsinline": true,
        "poster": img,
        "url": url,
        "x5-video-player-type": "h5",
        "playbackRate": [
          null
        ],
        plugins: [HlsPlugin]
      });
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.is_see = this.userInfo.is_see;
          if (this.userInfo.status !== 1) {
            this.$toast(this.$t("video.account_out"));
            localStorage.clear()
            this.$router.push({ path: '/Login' })
          }
          if (this.$store.getters.getBaseInfo.isplay == 1) {
            this.getVideoInfo();
            this.getMoreVideoItem()
          } else {
            this.getVideoInfo();
            this.getMoreVideoItem();
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
  },
  mounted() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo();
    }
  },
  destroyed() {
    if (this.is_play) {
      this.is_play = false;
    }
    clearInterval(this.times);
  }
};
</script>

<style scoped>
.video-js {
  width: 100%;
  /*height: 420px;*/
  font-size: 24px;
}

.movie-content {
  flex: 1;
  overflow-y: auto;
}

.movie-content .movie-descript {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
}

.movie-content .movie-descript p {
  font-size: 28px;
  font-weight: 700;
  color: #000000;
}

.movie-content .movie-descript span {
  color: #000000;
  font-size: 25px;
}

.movie-content .movie-body {
  width: calc(100% - 20px);
  margin: 0 auto;
}

::v-deep .movie-video .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}

.movie-video {
  width: 100%;
  display: block;
}

#mse {
  width: 100% !important;
  height: 450px !important;
  display: block;
}

.movie-content .movie-body .movie-title {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie-content .movie-body .movie-title>div:first-child {
  width: 410px;
}

.movie-content .movie-body .movie-title>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 700;
  color: #000000;
}

.movie-content .movie-body .movie-title>div:first-child span:before {
  content: "";
  display: block;
  width: 8px;
  height: 30px;
  background-color: #a6faf7;
  border-radius: 25px;
  margin-right: 10px;
}

.movie-play-item {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  position: relative;
  display: flex;
  background-color: #fff;
  margin-bottom: 20px;
}

.movie-play-item>div {
  height: 100%;
}

.movie-play-item>div:first-child {
  width: 200px;
  position: relative;
}

.movie-play-item>div:first-child>img {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  object-fit: cover;
}

.movie-play-item>div:first-child>div {
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background-color: rgba(0, 0, 0, .4);
  border-radius: 0 0 0 10px;
}

.movie-play-item>div:first-child>div .van-count-down {
  color: #fff;
  font-size: 25px;
}

.movie-play-item>div:nth-child(2) p {
  width: 500px;
  height: 60px;
  font-size: 30px;
  line-height: 32px;
  word-break: break-all;
  overflow: hidden;
  color: #000000;
}

.movie-play-item>div:nth-child(2) span {
  color: #000000;
}

.movie-play-item>div:nth-child(2) {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}

.vjs-big-play-button .vjs-icon-placeholder {
  font-size: 1.63em !important;
}

</style>
<style>
.xgplayer.xgplayer-mobile .xgplayer-start{
  height: 150px !important;
  width: 150px !important;
}
.xgplayer.xgplayer-mobile .xgplayer-time{
  font-size: 26px!important;
}
.xgplayer .xgplayer-progress-outer{
  height: 3px!important;
}
.xgplayer.xgplayer-mobile .xgplayer-time.xg-time-left{
  margin-right: 20px!important;
}
.xgplayer-mobile .xgplayer-loading{
  height: 100px !important;
  width: 100px !important;
}
</style>